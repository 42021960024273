import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { FaPatreon } from "react-icons/fa";

import { useEffectOnce } from "../functions/useEffectOnce";

function PagePatreon(props) {
    const { trackPageView } = useMatomo();

    useEffectOnce(() => {
        document.title = "Support - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        <b>
                                            <FaPatreon /> Patreon Benefits
                                        </b>
                                    </h1>
                                    <Button
                                        href="https://patreon.com/TwitchApexStats"
                                        target="_blank"
                                        className="m-2 text-black fw-bold"
                                        style={{ backgroundColor: "#f96854" }}
                                    >
                                        Visit on <FaPatreon /> Patreon!
                                    </Button>
                                </Col>
                            </Row>
                            <Row
                                className="mb-3"
                                style={{ margin: "auto", maxWidth: "800px" }}
                            >
                                <Col xs="12">
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>
                                                <span className="fw-bold fs-5">
                                                    This project is ran as free to use for anyone. However it is not
                                                    free for this project to operate. We offer a Patreon for people to
                                                    donate and support this project if they find it useful.
                                                </span>
                                                <br />
                                                <br />
                                                <span className="fw-bold fs-6">
                                                    For anyone that pledges $1 or more per month we offer the following
                                                    benefits currently:
                                                </span>
                                            </Card.Text>
                                            <ul>
                                                <li>
                                                    Remove "<code>(- Powered by TAS.gg -)</code>" from the end of
                                                    commands
                                                </li>
                                                <li>
                                                    Remove "<code>Powered by TAS.gg</code>" and related branding from
                                                    overlays
                                                </li>
                                                <li>
                                                    Additional "Auto Session Start" option of detecting when the game
                                                    account goes active rather than just when the stream goes live.
                                                </li>
                                            </ul>
                                            <Card.Text className="text-center">
                                                <Button
                                                    href="https://patreon.com/TwitchApexStats"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    size="sm"
                                                    className="m-2 text-black fw-bold"
                                                    style={{ backgroundColor: "#f96854" }}
                                                >
                                                    <FaPatreon /> Join!
                                                </Button>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
}

export default PagePatreon;
