import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Row, Col, Card, Container, Placeholder, Pagination } from "react-bootstrap";
import { Twitch, EyeFill } from "react-bootstrap-icons";
import ReactTimeAgo from "react-time-ago";

import { useEffectOnce } from "../functions/useEffectOnce";
import TwitchStreamContainer from "../components/twitch-streams/TwitchStreamContainer";

function PageTwitchStreams(props) {
    const { trackPageView } = useMatomo();
    const [streams, setStreams] = useState([]);
    const [streamsLoaded, setStreamsLoaded] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    useEffectOnce(() => {
        document.title = "Online Streams - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    // Get streams
    useEffectOnce(() => {
        setStreamsLoaded(false);
        axios
            .get("https://api.tas.gg/main/twitch-streams")
            .then((response) => {
                //setStreams(response.data);
                //setStreamsLoaded(true);
                cacheImagesAndSetData(response.data);
            })
            .catch((error) => {
                setStreamsLoaded(false);
            });
    });

    // Create 1 minute interval to refresh streams
    useEffectOnce(() => {
        const interval = setInterval(() => {
            axios
                .get("https://api.tas.gg/main/twitch-streams")
                .then((response) => {
                    //setStreams(response.data);
                    cacheImagesAndSetData(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }, 30000);
        return () => clearInterval(interval);
    });

    const cacheImagesAndSetData = async (streamData) => {
        // Preload thumbnail images
        const thumbnailPromises = streamData.streams.map((stream) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = stream.thumbnail_url.replace("{width}", "288").replace("{height}", "162");
                img.onload = resolve();
                img.onerror = reject();
            });
        });

        // Preload profile images
        const profilePromises = streamData.streams.map((stream) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = stream.profile_image_url;
                img.onload = resolve();
                img.onerror = reject();
            });
        });

        await Promise.all(thumbnailPromises);
        await Promise.all(profilePromises);

        setStreams(streamData);
        setStreamsLoaded(true);
    };

    // Make sure page number is valid
    useEffect(() => {
        if (streamsLoaded) {
            if (pageNumber < 1) {
                setPageNumber(1);
            } else if (pageNumber > Math.ceil(streams.streams.length / 20)) {
                setPageNumber(Math.ceil(streams.streams.length / 20));
            }
        }
    }, [streamsLoaded, pageNumber, streams]);

    const totalPages = streams.streams !== undefined ? Math.ceil(streams.streams.length / 20) : 0;
    const pagesToShow = 5; // Number of pages to show (including current page)

    // Calculate the range of page numbers to display
    const startPage = Math.max(1, pageNumber - 2);
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    // Generate the page items
    const pageItems = Array.from(Array(endPage - startPage + 1).keys()).map((i) => (
        <Pagination.Item
            className="d-block d-lg-none"
            key={"Streams-Page" + (startPage + i)}
            active={pageNumber === startPage + i}
            onClick={() => setPageNumber(startPage + i)}
        >
            {startPage + i}
        </Pagination.Item>
    ));

    // Add the first and last page items
    if (startPage > 1) {
        pageItems.unshift(
            <Pagination.First
                className="d-none d-sm-block d-lg-none"
                key="Streams-FirstPage"
                onClick={() => setPageNumber(1)}
            />
        );
    }
    if (endPage < totalPages) {
        pageItems.push(
            <Pagination.Last
                className="d-none d-sm-block d-lg-none"
                key="Streams-LastPage"
                onClick={() => setPageNumber(totalPages)}
            />
        );
    }

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        <b>
                                            <Twitch /> Online Twitch Streams
                                        </b>
                                    </h1>
                                    Below are online Twitch streams that have used our tools in the last 7 days.
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                {streamsLoaded ? (
                                    <>
                                        <Col xs={12}>
                                            <Pagination className="justify-content-center">
                                                <Pagination.Item
                                                    key="Page"
                                                    disabled
                                                >
                                                    Page
                                                </Pagination.Item>
                                                <Pagination.Prev
                                                    disabled={pageNumber === 1}
                                                    onClick={() => setPageNumber(pageNumber - 1)}
                                                />
                                                {Array.from(Array(Math.ceil(streams.streams.length / 20)).keys()).map(
                                                    (i) => (
                                                        <Pagination.Item
                                                            className="d-none d-lg-block"
                                                            key={"Streams-Page" + (i + 1)}
                                                            active={pageNumber === i + 1}
                                                            onClick={() => setPageNumber(i + 1)}
                                                        >
                                                            {i + 1}
                                                        </Pagination.Item>
                                                    )
                                                )}
                                                {pageItems}
                                                <Pagination.Next
                                                    disabled={pageNumber === Math.ceil(streams.streams.length / 20)}
                                                    onClick={() => setPageNumber(pageNumber + 1)}
                                                />
                                                <Pagination.Item
                                                    key="total-streams"
                                                    disabled
                                                    className="d-none d-lg-block"
                                                >
                                                    {streams.streams.length} Total Streams
                                                </Pagination.Item>
                                            </Pagination>
                                        </Col>
                                        {streams.streams.slice((pageNumber - 1) * 20, pageNumber * 20).map((stream) => (
                                            <TwitchStreamContainer
                                                key={stream.channel_id}
                                                stream_data={stream}
                                            />
                                        ))}
                                        <Col xs={12}>
                                            <Pagination className="justify-content-center">
                                                <Pagination.Item
                                                    key="Page"
                                                    disabled
                                                >
                                                    Page
                                                </Pagination.Item>
                                                <Pagination.Prev
                                                    disabled={pageNumber === 1}
                                                    onClick={() => setPageNumber(pageNumber - 1)}
                                                />
                                                {Array.from(Array(Math.ceil(streams.streams.length / 20)).keys()).map(
                                                    (i) => (
                                                        <Pagination.Item
                                                            className="d-none d-lg-block"
                                                            key={"Streams-Page" + (i + 1)}
                                                            active={pageNumber === i + 1}
                                                            onClick={() => setPageNumber(i + 1)}
                                                        >
                                                            {i + 1}
                                                        </Pagination.Item>
                                                    )
                                                )}
                                                {pageItems}
                                                <Pagination.Next
                                                    disabled={pageNumber === Math.ceil(streams.streams.length / 20)}
                                                    onClick={() => setPageNumber(pageNumber + 1)}
                                                />
                                                <Pagination.Item
                                                    key="total-streams"
                                                    disabled
                                                    className="d-none d-lg-block"
                                                >
                                                    {streams.streams.length} Total Streams
                                                </Pagination.Item>
                                            </Pagination>
                                        </Col>
                                        <Col
                                            xs="12"
                                            className="text-center"
                                        >
                                            Stream data from{" "}
                                            <ReactTimeAgo
                                                date={streams.timestamp * 1000}
                                                timeStyle="round"
                                            />
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        {Array.from(Array(20).keys()).map((i) => (
                                            <Col
                                                className="text-center mb-3 p-0"
                                                key={"Stream-Placeholder-" + i}
                                            >
                                                <Card
                                                    className="bg-dark p-0 mx-auto"
                                                    style={{ width: "300px" }}
                                                >
                                                    <Card.Body className="pt-1 pb-1">
                                                        <Row>
                                                            <div className="col-12 p-0 mb-1">
                                                                <div
                                                                    className="twitch-streams-thumbnail bg-secondary"
                                                                    style={{
                                                                        width: "288px",
                                                                        height: "162px",
                                                                        animation:
                                                                            "placeholder-glow 2s ease-in-out infinite",
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-12 p-0 text-white text-ellipsis fw-bold">
                                                                <Placeholder animation="glow">
                                                                    <Placeholder xs={9} />
                                                                </Placeholder>
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-8 text-start p-0">
                                                                <div
                                                                    className="twitch-streams-channel-icon bg-secondary"
                                                                    style={{
                                                                        width: "35px",
                                                                        height: "35px",
                                                                        float: "left",
                                                                        animation:
                                                                            "placeholder-glow 2s ease-in-out infinite",
                                                                    }}
                                                                ></div>
                                                                &nbsp;
                                                                <span className="h6 text-white">
                                                                    <Placeholder animation="glow">
                                                                        <Placeholder xs={6} />
                                                                    </Placeholder>
                                                                </span>
                                                            </div>
                                                            <div className="col-4 text-end p-0">
                                                                <span className="text-white">
                                                                    <EyeFill />{" "}
                                                                    <Placeholder animation="glow">
                                                                        <Placeholder xs={6} />
                                                                    </Placeholder>
                                                                </span>
                                                            </div>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
}

export default PageTwitchStreams;
