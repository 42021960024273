import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { TrophyFill } from "react-bootstrap-icons";
import { Row, Col, Card, Container, Placeholder, Button, Carousel, Badge, Alert } from "react-bootstrap";
import { Twitter, Discord, Twitch, PersonCircle, Paypal } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { FaPatreon } from "react-icons/fa";

import { useEffectOnce } from "../functions/useEffectOnce";

import axios from "axios";

import AdSense from "react-adsense";

function PageMain(props) {
    const { trackPageView } = useMatomo();
    const [basicUsageStats, setBasicUsageStats] = React.useState({});
    const [basicUsageStatsLoaded, setBasicUsageStatsLoaded] = React.useState(false);

    useEffectOnce(() => {
        document.title = "Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    // Get basic usage stats
    useEffectOnce(() => {
        axios
            .get("https://api.tas.gg/main/basic-usage-stats")
            .then((response) => {
                setBasicUsageStats(response.data);
                setBasicUsageStatsLoaded(true);
            })
            .catch((error) => {
                console.log(error);
            });
    });

    // Get latest basic usage stats on a 1 minute interval
    useEffectOnce(() => {
        const interval = setInterval(() => {
            axios
                .get("https://api.tas.gg/main/basic-usage-stats")
                .then((response) => {
                    setBasicUsageStats(response.data);
                    setBasicUsageStatsLoaded(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }, 60000);
        return () => clearInterval(interval);
    });

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 pt-lg-1 pt-xl-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        Welcome to <br />
                                        <b>Twitch Apex Stats</b>
                                    </h1>
                                    <Button
                                        href="https://dashboard.tas.gg"
                                        variant="primary"
                                    >
                                        <PersonCircle /> Access Dashboard
                                    </Button>
                                    <div className="fw-bold fs-4 pt-4">
                                        <Placeholder animation="glow">
                                            Used on{" "}
                                            {basicUsageStatsLoaded ? (
                                                basicUsageStats.channels.toLocaleString()
                                            ) : (
                                                <Placeholder xs={1} />
                                            )}{" "}
                                            Twitch channels, with{" "}
                                            {basicUsageStatsLoaded ? (
                                                basicUsageStats.commands.toLocaleString()
                                            ) : (
                                                <Placeholder xs={1} />
                                            )}{" "}
                                            commands ran!
                                        </Placeholder>
                                    </div>
                                    {/** New Feature alert
                                    <Alert
                                        variant="success"
                                        className="mt-3 text-white p-0 pt-1 mb-0"
                                    >
                                        <Alert.Heading className="text-white">
                                            <b>Commands for Realm Added!</b>
                                        </Alert.Heading>
                                        <p className="p-0 fw-bold">
                                            Stats commands for Realm have been added.{" "}
                                            <a
                                                href="https://dashboard.tas.gg"
                                                className="text-white"
                                            >
                                                Set them up in your dashboard!
                                            </a>
                                        </p>
                                    </Alert> */}
                                    <div className="fw-bold fs-6 pt-4">
                                        Looking for commands for tournament standings and scores?
                                        <br />
                                        <span
                                            style={{ fontSize: "0.75rem" }}
                                            className="text-muted fst-italic"
                                        >
                                            (ALGS, Battlefy, Apex Legends Status DGS and CGS)
                                            <br />
                                        </span>
                                        <Button
                                            href="https://tournaments.tas.gg"
                                            size="sm"
                                            variant="secondary"
                                        >
                                            <TrophyFill /> Tournament Commands
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs="12">
                                    <Carousel
                                        slide={true}
                                        indicators={false}
                                        pause="hover"
                                        style={{ margin: "auto", maxWidth: "800px", minHeight: "300px" }}
                                    >
                                        <Carousel.Item
                                            className="text-center rounded-start rounded-end"
                                            style={{
                                                backgroundImage:
                                                    "url('https://twitchapexstats.com/imgs/mainpage/index-slider-1-bg.jpg')",
                                                minHeight: "574.969px",
                                            }}
                                        >
                                            <Row
                                                style={{
                                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                                    minHeight: "574.969px",
                                                }}
                                            >
                                                <Col
                                                    xs="12"
                                                    className="pt-3 pb-3 w-100 my-auto fs-5"
                                                    style={{ paddingLeft: "15%", paddingRight: "15%" }}
                                                >
                                                    <h2>
                                                        <b>Welcome!</b>
                                                    </h2>
                                                    <p>
                                                        <b>Twitch Apex Stats</b> is a collection of tools to add
                                                        additional functionality to your Twitch streams for when you are
                                                        playing Apex Legends. You can add additional commands to your
                                                        current Twitch bot to let your viewers see your stats and other
                                                        details related to Apex Legends. After added to Nightbot, Stream
                                                        Elements, PhantomBot, Moobot, Fossabot, or other supported bots
                                                        your viewers can use commands to view your stats.
                                                    </p>
                                                    <Button
                                                        href="https://dashboard.tas.gg"
                                                        variant="primary"
                                                        className="fw-bold fs-5"
                                                    >
                                                        Get Started!
                                                    </Button>
                                                    <br />
                                                    <br />
                                                </Col>
                                            </Row>
                                        </Carousel.Item>
                                        <Carousel.Item
                                            className="text-center rounded-start rounded-end"
                                            style={{
                                                backgroundImage:
                                                    "url('https://twitchapexstats.com/imgs/mainpage/index-slider-1-bg.jpg')",
                                                minHeight: "574.969px",
                                            }}
                                        >
                                            <div
                                                className="row"
                                                style={{
                                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                                    minHeight: "574.969px",
                                                }}
                                            >
                                                <div
                                                    className="col-12 pt-3 pb-3 w-100 my-auto"
                                                    style={{ paddingLeft: "15%", paddingRight: "15%" }}
                                                >
                                                    <h2>
                                                        <b>Get Commands like:</b>
                                                    </h2>

                                                    <p className="mb-1">
                                                        <Badge
                                                            bg="primary"
                                                            className="fw-bold fs-6 p-1"
                                                        >
                                                            Overview Stats
                                                        </Badge>
                                                        <br />
                                                        See an overview of your game stats like level, ranking, and
                                                        legend details
                                                    </p>
                                                    <p className="mb-1">
                                                        <Badge
                                                            bg="primary"
                                                            className="fw-bold fs-6 p-1"
                                                        >
                                                            Session Stats
                                                        </Badge>
                                                        <br />
                                                        Show how much change in ranked stats you've had in your current
                                                        gaming session
                                                    </p>
                                                    <p className="mb-1">
                                                        <Badge
                                                            bg="primary"
                                                            className="fw-bold fs-6 p-1"
                                                        >
                                                            Top 5 Ranked Players
                                                        </Badge>
                                                        <br />
                                                        See who are currently the top 5 ranked players are on your
                                                        platform
                                                    </p>
                                                    <p className="mb-1">
                                                        <Badge
                                                            bg="primary"
                                                            className="fw-bold fs-6 p-1"
                                                        >
                                                            Map Rotation
                                                        </Badge>
                                                        <br />
                                                        See what the current and upcoming maps are in each game mode
                                                    </p>
                                                    <p className="mb-1">
                                                        <Badge
                                                            bg="primary"
                                                            className="fw-bold fs-6 p-1"
                                                        >
                                                            Game Server Status
                                                        </Badge>
                                                        <br />
                                                        Quickly see if there are any known issues with the game's
                                                        servers.
                                                        <br />
                                                        (Data provided by{" "}
                                                        <a
                                                            href="https://apexlegendstatus.com/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="fw-bold"
                                                        >
                                                            ApexLegendStatus.com
                                                        </a>
                                                        )
                                                    </p>
                                                    <p className="mb-1">
                                                        <Badge
                                                            bg="primary"
                                                            className="fw-bold fs-6 p-1"
                                                        >
                                                            ...and more!
                                                        </Badge>
                                                        <br />
                                                        See a full summary of all commands here:{" "}
                                                        <Link
                                                            to="/commands"
                                                            className="fw-bold"
                                                        >
                                                            Command Examples
                                                        </Link>
                                                    </p>
                                                    <br />
                                                    <Button
                                                        href="https://dashboard.tas.gg"
                                                        variant="primary"
                                                        className="fw-bold fs-5"
                                                    >
                                                        Get Started!
                                                    </Button>
                                                    <br />
                                                    <Button
                                                        as={Link}
                                                        variant="secondary"
                                                        size="sm"
                                                        to="/commands"
                                                        className="mt-1"
                                                    >
                                                        See all Command Examples
                                                    </Button>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item
                                            className="text-center rounded-start rounded-end"
                                            style={{
                                                backgroundImage:
                                                    "url('https://twitchapexstats.com/imgs/mainpage/index-slider-2-bg.jpg')",
                                                minHeight: "574.969px",
                                            }}
                                        >
                                            <div
                                                className="row"
                                                style={{
                                                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                                                    minHeight: "574.969px",
                                                }}
                                            >
                                                <div
                                                    className="col-12 pt-3 pb-3 w-100 my-auto fs-5"
                                                    style={{ paddingLeft: "15%", paddingRight: "15%" }}
                                                >
                                                    <h2>
                                                        <b>Get Stream Overlays</b>
                                                    </h2>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="275"
                                                        height="90"
                                                        viewBox="0 0 350 116"
                                                    >
                                                        <path
                                                            d="M17.542,80H350V10L340,0H0V57.429Z"
                                                            stroke="none"
                                                            fill="rgba(0, 0, 0, 0.6)"
                                                        ></path>
                                                        <path
                                                            d="M 348 78 L 348 10.82845497131348 L 339.1715393066406 2 L 2 2 L 2 56.74310684204102 L 18.52031898498535 78 L 348 78 M 350 80 L 17.54168128967285 80 L 0 57.42890930175781 L 0 0 L 340 0 L 350 10 L 350 80 Z"
                                                            stroke="none"
                                                            fill="rgba(255, 255, 255, 0.5)"
                                                        ></path>
                                                        <g transform="translate(-1500 -225)">
                                                            <image
                                                                id="value-rank-badge"
                                                                width="80"
                                                                height="60"
                                                                transform="translate(1505 235)"
                                                                href="https://assets.tas.gg/br-ranked-badge.php?rank=Apex%20Predator&division=0&position=138"
                                                            ></image>
                                                            <text
                                                                transform="translate(1590 248)"
                                                                fontWeight="800"
                                                            >
                                                                <tspan
                                                                    id="value-current-rank"
                                                                    x="0"
                                                                    y="0"
                                                                    fill="rgba(255, 255, 255, 1)"
                                                                    fontSize="22"
                                                                    fontFamily="ApexFont"
                                                                >
                                                                    Apex Predator #138
                                                                </tspan>
                                                            </text>
                                                            <text
                                                                transform="translate(1590 270)"
                                                                fontWeight="700"
                                                            >
                                                                <tspan
                                                                    x="0"
                                                                    y="0"
                                                                    fill="rgba(207, 207, 207, 1)"
                                                                    fontSize="18"
                                                                    fontFamily="ApexFont"
                                                                >
                                                                    Current RP
                                                                </tspan>
                                                                <tspan
                                                                    id="value-current-rp"
                                                                    x="0"
                                                                    y="26"
                                                                    fill="rgba(255, 255, 255, 1)"
                                                                    fontSize="30"
                                                                    fontFamily="ApexFont"
                                                                >
                                                                    17,634
                                                                </tspan>
                                                            </text>
                                                            <text
                                                                transform="translate(1735 270)"
                                                                fontWeight="700"
                                                            >
                                                                <tspan
                                                                    x="0"
                                                                    y="0"
                                                                    fill="rgba(207, 207, 207, 1)"
                                                                    fontSize="18"
                                                                    fontFamily="ApexFont"
                                                                >
                                                                    Change
                                                                </tspan>
                                                                <tspan
                                                                    id="value-rp-change"
                                                                    x="0"
                                                                    y="26"
                                                                    fill="rgba(255, 255, 255, 1)"
                                                                    fontSize="30"
                                                                    fontFamily="ApexFont"
                                                                >
                                                                    +421
                                                                </tspan>
                                                            </text>
                                                        </g>
                                                    </svg>
                                                    <br />
                                                    <p>
                                                        Stream overlays are now available for anyone. Overlays are
                                                        available to show session rank point changes, legend trackers,
                                                        and more! The overlays automatically update so that your viewers
                                                        can quickly see your latest stats. Each overlay can be
                                                        configured to match the color scheme of your stream. Support on
                                                        Patreon for as little as $1 per month to remove the Powered By
                                                        branding from overlays. These Patreon pledges help cover the
                                                        costs of running this service.
                                                    </p>
                                                    <Button
                                                        variant="primary"
                                                        as={Link}
                                                        to="/overlays"
                                                        className="mt-1"
                                                    >
                                                        See Overlay Examples
                                                    </Button>
                                                    <br />
                                                    <Button
                                                        href="https://patreon.com/TwitchApexStats"
                                                        target="_blank"
                                                        size="sm"
                                                        rel="noreferrer"
                                                        variant="secondary"
                                                        className="m-1 text-black"
                                                        style={{ backgroundColor: "#f96854" }}
                                                    >
                                                        Support on <FaPatreon /> Patreon
                                                    </Button>
                                                    <br />

                                                    <br />
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row>
                            <Row style={{ margin: "auto", maxWidth: "800px" }}>
                                <Col
                                    xs="12"
                                    md="6"
                                    className="mt-3"
                                >
                                    <Card className="h-100">
                                        <Card.Body>
                                            <Card.Title className="fs-3 text-center">Get Started!</Card.Title>
                                            <Card.Text>
                                                To get started, head over to the{" "}
                                                <a href="https://dashboard.tas.gg/">User Dashboard</a>. If needed, add a
                                                Twitch channel that you have mod access to. Add a game account if needed
                                                to that channel. Then check out the Account Commands and Overlay
                                                sections for that channel.
                                            </Card.Text>
                                            <div className="text-center">
                                                <Button
                                                    href="https://dashboard.tas.gg"
                                                    variant="primary"
                                                    className="m-2"
                                                >
                                                    <PersonCircle /> Access Dashboard
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col
                                    xs="12"
                                    md="6"
                                    className="mt-3"
                                >
                                    <Card className="h-100">
                                        <Card.Body>
                                            <Card.Title className="fs-3 text-center">Support Us!</Card.Title>
                                            <Card.Text>
                                                <b>Twitch Apex Stats</b> is ran for free but relies on support from our
                                                generous Patreon supporters. By pledging at least $1 to our Patreon you
                                                can remove the <i>Powered By</i> branding from your commands and
                                                overlays, additional automatic session start modes, and more features
                                                coming soon! We also accept donations via PayPal if you prefer to just
                                                send some money our way to keep this project going.
                                            </Card.Text>
                                            <div className="text-center">
                                                <Button
                                                    href="https://patreon.com/TwitchApexStats"
                                                    target="_blank"
                                                    size="sm"
                                                    variant="secondary"
                                                    className="m-2 text-black fw-bold"
                                                    style={{ backgroundColor: "#f96854" }}
                                                >
                                                    Join our <FaPatreon /> Patreon!
                                                </Button>

                                                <br />
                                                <Button
                                                    href="https://www.paypal.com/donate/?business=C9WLUSATV3Z9C&no_recurring=0&item_name=Twitch+Apex+Stats+is+ran+to+help+the+Apex+community.+I+would+love+for+your+help+to+make+sure+it+sticks+around+for+the+future.&currency_code=USD"
                                                    target="_blank"
                                                    size="sm"
                                                    variant="secondary"
                                                    className="m-2 text-black fw-bold"
                                                    style={{ backgroundColor: "#009cde" }}
                                                >
                                                    <Paypal /> Donate via PayPal
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row
                                className="mb-3 mt-3"
                                style={{ margin: "auto", maxWidth: "800px" }}
                            >
                                <Col sm="12">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="fs-3 text-center">Created By</Card.Title>
                                            <Card.Text>
                                                <b>Twitch Apex Stats</b> and related tools are created by Casey
                                                Blackburn, a technology consultant and software developer that enjoys
                                                playing Apex Legends as well as being a part of the community.
                                            </Card.Text>
                                            <div className="text-center">
                                                <Button
                                                    href="https://twitter.com/MrCaseyJames"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    variant="primary"
                                                    className="m-2"
                                                >
                                                    <Twitter /> @MrCaseyJames
                                                </Button>
                                                <Button
                                                    href="https://discordapp.com/users/471792768503513088"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    variant="secondary"
                                                    className="m-2"
                                                >
                                                    <Discord /> @mrcaseyjames
                                                </Button>
                                                <Button
                                                    href="https://twitch.tv/caseyblackburn"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    variant="secondary"
                                                    className="m-2"
                                                >
                                                    <Twitch /> CaseyBlackburn
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
}

export default PageMain;
