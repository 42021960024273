import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { Twitter, Discord, Twitch, QuestionCircleFill } from "react-bootstrap-icons";

import { useEffectOnce } from "../functions/useEffectOnce";

function PageSupport(props) {
    const { trackPageView } = useMatomo();

    useEffectOnce(() => {
        document.title = "Support - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        <b>
                                            <QuestionCircleFill /> Help & Support
                                        </b>
                                    </h1>
                                </Col>
                            </Row>
                            <Row
                                className="mb-3"
                                style={{ margin: "auto", maxWidth: "800px" }}
                            >
                                <Col xs="12">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="fs-3 text-center">Support Methods</Card.Title>
                                            <Card.Text>
                                                If you need help using any of the tools provided by Twitch Apex Stats,
                                                you can join the{" "}
                                                <a
                                                    href="https://discord.gg/hkGgMZkanz"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Discord /> Discord server
                                                </a>{" "}
                                                or reach out on{" "}
                                                <a
                                                    href="https://twitter.com/MrCaseyJames"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Twitter /> Twitter
                                                </a>
                                                .
                                            </Card.Text>
                                            <div className="text-center">
                                                <Button
                                                    href="https://discord.gg/hkGgMZkanz"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    variant="primary"
                                                    className="m-2"
                                                >
                                                    <Discord /> Discord Server
                                                </Button>
                                                <Button
                                                    href="https://twitter.com/MrCaseyJames"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    variant="secondary"
                                                    className="m-2"
                                                >
                                                    <Twitter /> @MrCaseyJames
                                                </Button>
                                                <Button
                                                    href="https://twitch.tv/CaseyBlackburn"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    variant="secondary"
                                                    className="m-2"
                                                >
                                                    <Twitch /> CaseyBlackburn
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
}

export default PageSupport;
