import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Table, Spinner } from "react-bootstrap";

import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend, Title } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, ChartTooltip, Legend, Title);

function ChannelReportContainer(props) {
    const [channel, setChannel] = useState(props.channel);
    const [channelData, setChannelData] = useState({});
    const [channelDataLoaded, setChannelDataLoaded] = useState(false);

    useEffect(() => {
        axios
            .get(`https://api.tas.gg/main/channel-report?channel=${channel}`)
            .then((response) => {
                setChannelData(response.data);
                setChannelDataLoaded(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [channel]);

    useEffect(() => {
        setChannel(props.channel);
        setChannelDataLoaded(false);
    }, [props.channel]);

    // Timer to update the data every 1 minute
    useEffect(() => {
        const interval = setInterval(() => {
            axios
                .get(`https://api.tas.gg/main/channel-report?channel=${channel}`)
                .then((response) => {
                    setChannelData(response.data);
                    setChannelDataLoaded(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }, 60000);
        return () => clearInterval(interval);
    }, [channel]);

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        //const labelIndex = (context.datasetIndex * 2) + context.dataIndex;
                        const labelIndex = context.dataIndex;
                        if (context.datasetIndex === 0) {
                            return (
                                context.chart.data.labels[labelIndex] +
                                ": " +
                                context.formattedValue +
                                " Requests in Last 24 Hours"
                            );
                        } else if (context.datasetIndex === 1) {
                            return (
                                context.chart.data.labels[labelIndex] +
                                ": " +
                                context.formattedValue +
                                " Requests in Last 3 Days"
                            );
                        } else if (context.datasetIndex === 2) {
                            return (
                                context.chart.data.labels[labelIndex] +
                                ": " +
                                context.formattedValue +
                                " Requests in Last 7 Days"
                            );
                        } else if (context.datasetIndex === 3) {
                            return (
                                context.chart.data.labels[labelIndex] +
                                ": " +
                                context.formattedValue +
                                " Lifetime Requests"
                            );
                        } else {
                            return context.chart.data.labels[labelIndex] + ": " + context.formattedValue + " Sometime";
                        }
                    },
                },
            },
            title: {
                display: true,
                text: "Function Requests",
            },
        },
    };

    return (
        <>
            {channelDataLoaded ? (
                channelData.total.lifetime === 0 ? (
                    <Row>
                        <Col xs="12" className="text-center mt-3">
                            <h3>No Data Available for Channel: {channel}</h3>
                            Please make sure the channel you entered is correct and that commands have been used on that
                            channel. You may also need to verify the commands are setup correctly on the channel.
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col xs="12" className="text-center mt-3">
                                <h3>Report for Channel: {channel}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" md="6">
                                <Pie data={channelData.chart_data} options={chartOptions} />
                            </Col>
                            <Col xs="12" md="6">
                                <h3>Command Usage Over Time</h3>
                                <Table hover striped>
                                    <thead>
                                        <tr>
                                            <th>Function</th>
                                            <th>Lifetime</th>
                                            <th>Last 7D</th>
                                            <th>Last 3D</th>
                                            <th>Last 24H</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {channelData.commands.map((command, index) => (
                                            <tr key={index}>
                                                <th>{command.function}</th>
                                                <td>
                                                    {command.lifetime.toLocaleString()}
                                                    <br />
                                                    <span style={{ fontSize: "75%" }}>
                                                        (
                                                        {formatAsPercent(command.lifetime / channelData.total.lifetime)}
                                                        )
                                                    </span>
                                                </td>
                                                <td>
                                                    {command.seven_day.toLocaleString()}
                                                    <br />
                                                    <span style={{ fontSize: "75%" }}>
                                                        (
                                                        {formatAsPercent(
                                                            command.seven_day / channelData.total.seven_day
                                                        )}
                                                        )
                                                    </span>
                                                </td>
                                                <td>
                                                    {command.three_day.toLocaleString()}
                                                    <br />
                                                    <span style={{ fontSize: "75%" }}>
                                                        (
                                                        {formatAsPercent(
                                                            command.three_day / channelData.total.three_day
                                                        )}
                                                        )
                                                    </span>
                                                </td>
                                                <td>
                                                    {command.one_day.toLocaleString()}
                                                    <br />
                                                    <span style={{ fontSize: "75%" }}>
                                                        ({formatAsPercent(command.one_day / channelData.total.one_day)})
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Totals</th>
                                            <td>{channelData.total.lifetime.toLocaleString()}</td>
                                            <td>{channelData.total.seven_day.toLocaleString()}</td>
                                            <td>{channelData.total.three_day.toLocaleString()}</td>
                                            <td>{channelData.total.one_day.toLocaleString()}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                            <Col xs="12" style={{ overflowX: "auto" }}>
                                <h3>Last 50 Command Request History</h3>
                                <Table hover striped>
                                    <thead>
                                        <tr>
                                            <th scope="col">Timestamp</th>
                                            <th scope="col">Bot</th>
                                            <th scope="col">Function</th>
                                            <th scope="col">Chatter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {channelData.history.map((history, index) => (
                                            <tr key={index}>
                                                <th scope="row">
                                                    {timestampToDate(history.timestamp).toLocaleString("en-US", {
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                        second: "numeric",
                                                        timeZoneName: "short",
                                                    })}{" "}
                                                    -{" "}
                                                    {timestampToDate(history.timestamp).toLocaleString("en-US", {
                                                        weekday: "short",
                                                        month: "short",
                                                        day: "numeric",
                                                        year: "numeric",
                                                    })}
                                                </th>
                                                <td>{history.bot}</td>
                                                <td>{history.function}</td>
                                                <td>{history.chatter}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                )
            ) : (
                <div className="text-center col-auto mx-auto pt-5">
                    <Spinner
                        animation="border"
                        variant="primary"
                        style={{
                            width: "8rem",
                            height: "8rem",
                        }}
                    />
                </div>
            )}
        </>
    );
}

function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(num);
}

function timestampToDate(timestamp) {
    return new Date(timestamp * 1000);
}

export default ChannelReportContainer;
