import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Row, Col, Container, Button, InputGroup, Form } from "react-bootstrap";
import { Clipboard2DataFill } from "react-bootstrap-icons";

import { useEffectOnce } from "../functions/useEffectOnce";

import ChannelReportContainer from "../components/channel-report/ChannelReportContainer";

function PageChannelReport(props) {
    const { trackPageView } = useMatomo();
    const { channelName } = useParams();
    const navigate = useNavigate();
    const [channel, setChannel] = useState(channelName ? channelName : "");
    const [lookUpChannel, setLookUpChannel] = useState(channelName ? channelName : "");

    useEffectOnce(() => {
        document.title = "Channel Report - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        // If location.pathname changes, update the channel name
        if (window.location.pathname.split("/")[1] === "channel-report") {
            setChannel(window.location.pathname.split("/")[2]);
            setLookUpChannel(window.location.pathname.split("/")[2]);
        }
    }, [window.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        <b>
                                            <Clipboard2DataFill /> Channel Report
                                        </b>
                                    </h1>
                                </Col>
                            </Row>
                            <Row style={{ margin: "auto", maxWidth: "800px" }}>
                                <Col xs="12" className="text-center">
                                    <span className="fs-4">
                                        View the usage of commands on your channel over time, see which commands are
                                        used the most. Also view the history of the last 50 commands used.
                                    </span>
                                    <br />
                                    <br />
                                    <span className="fs-5">
                                        Enter the name of your Twitch channel to view your report:
                                    </span>
                                    <br />
                                    <InputGroup className="mb-3" style={{ maxWidth: "400px", margin: "auto" }}>
                                        <InputGroup.Text>Twitch Channel</InputGroup.Text>
                                        <Form.Control
                                            placeholder="PlayApex"
                                            aria-label="Channel Name"
                                            aria-describedby="Channel-Name"
                                            onChange={(e) => setChannel(e.target.value)}
                                            value={channel ? channel : ""}
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    setLookUpChannel(channel);
                                                    navigate(`/channel-report/${channel}`);
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                    <Button
                                        variant="primary"
                                        id="Channel-Name"
                                        onClick={() => {
                                            setLookUpChannel(channel);
                                            navigate(`/channel-report/${channel}`);
                                        }}
                                    >
                                        Show Report
                                    </Button>
                                </Col>
                            </Row>
                            {lookUpChannel ? <ChannelReportContainer channel={lookUpChannel} /> : null}
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
}

export default PageChannelReport;
