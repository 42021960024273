import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";

TimeAgo.addDefaultLocale(en);

const matomoInstance = createInstance({
    urlBase: "https://analytics.tas.gg/matomo/",
    siteId: 1,
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10, // optional, default value: `15
    },
    linkTracking: true, // optional, default value: true
    configurations: {},
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <MatomoProvider value={matomoInstance}>
        <div className="d-flex flex-column min-vh-100">
            <App />
        </div>
    </MatomoProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
