import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Container } from "react-bootstrap";

import { useEffectOnce } from "../functions/useEffectOnce";
import { Discord, Twitter } from "react-bootstrap-icons";

function PageUpdate(props) {
    const { trackPageView } = useMatomo();

    useEffectOnce(() => {
        document.title = "New Version - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        <b>New Major Update Now Launched!</b>
                                    </h1>
                                </Col>
                            </Row>
                            <Row
                                className="mb-3"
                                style={{ margin: "auto", maxWidth: "1200px" }}
                            >
                                <Col xs="12">
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>
                                                <span className="fw-bold fs-6">
                                                    After a lot of work, Twitch Apex Stats has been rebuild from the
                                                    ground up to bring a lot of new features and increase user
                                                    experience, many backend improvements were made as well.
                                                    <br />
                                                    <br />
                                                    TLDR below, but I ask that you read everything.
                                                </span>
                                            </Card.Text>
                                            <ul>
                                                <li>
                                                    New user dashboard for everyone to manage accounts. (
                                                    <a href="https://dashboard.tas.gg">User Dashboard</a>)
                                                </li>
                                                <li>
                                                    A free version of overlays are now available for everyone to use.
                                                </li>
                                                <li>
                                                    Everyone now has the option to make their game sessions
                                                    automatically start when their stream goes live.
                                                </li>
                                                <li>French has been added as a supported language for tools.</li>
                                                <li>
                                                    Overlays now support the multiple languages that are available.
                                                    (English, French, Russian)
                                                </li>
                                                <li>
                                                    If your commands/overlays were setup correctly originally with valid
                                                    details, they should still work without change. If they don't,
                                                    recreate them on the{" "}
                                                    <a href="https://dashboard.tas.gg">User Dashboard</a>. It's
                                                    recommended to recreate them anyways to prevent any issues in the
                                                    future.
                                                </li>
                                            </ul>
                                            <h1>The Full Details</h1>
                                            <Card.Text className="text-center">
                                                When this project was first started, I just through together a quick set
                                                of commands and overlays to help out the community. Over time, I added
                                                more features and more commands, but the code wasn't pretty and it was
                                                getting harder to maintain and quickly add new features. I never
                                                expected the project to grow as much as it has and be used by so many
                                                people. I felt it was time to rebuild the project from the ground up so
                                                overall everything would be a better experience for everyone and many
                                                new features could be added.
                                                <br />
                                                <br />
                                            </Card.Text>
                                            <h3>New User Dashboard</h3>
                                            <Card.Text className="text-center">
                                                The way channels and commands were setup and managed in the past relied
                                                on the person setting things up enter things correctly and consistently.
                                                This was a lot to expect from people and it was easy for mistakes to be
                                                made when things were being added and changed by multiple channel
                                                moderators. The new dashboard hopes to fix a lot of these issues.
                                                <br />
                                                <br />
                                                <img
                                                    src="https://twitchapexstats.com/imgs/update/Dashboard.png"
                                                    alt="User Dashboard"
                                                    style={{ maxWidth: "100%" }}
                                                />
                                                <br />
                                                <br />
                                                Users now login with their Twitch account and are able to manage their
                                                own Twitch channel or other channels they moderate. This allows for many
                                                settings to be stored in a central location rather than included in each
                                                command/overlay URL.
                                            </Card.Text>
                                            <h5>Concept of Default Game Account/Multiple Game Accounts</h5>
                                            <Card.Text className="text-center">
                                                This change brings about a new concept of a default game account and
                                                allowing multiple game accounts on a single channel. Many streamers are
                                                known to occasionally do Solo Rookie/Bronze to Masters runs or have a
                                                solo queue account and a full stack queue account. Having both setup in
                                                the user dashboard allows for the same commands/overlays to be used for
                                                both accounts just by switching the default account. Or commands and
                                                overlays can easily be setup specifically for one account.
                                            </Card.Text>
                                            <h3>New Free Features</h3>
                                            <Card.Text className="text-center">
                                                I've been hesitant to add these free features because they have been key
                                                to driving people to become Patreon supporters to help support the
                                                expenses of running these tools. However, I've wanted to provide more
                                                for more people, so I just hope that people will continue to support the
                                                project if they can.
                                            </Card.Text>
                                            <ul>
                                                <li>
                                                    <b>Overlays - </b>
                                                    This has been probably the most requested feature, Overlays now have
                                                    a free option for everyone. Similar to commands, the free version of
                                                    overlays have Powered By branding on them. So now everyone can use
                                                    the various stats overlays on their streams.
                                                </li>
                                                <li>
                                                    <b>Auto Start Game Sessions on Going Live - </b>
                                                    Formally an exclusive Patreon feature, this is now available for
                                                    everyone. You can set the game session for an account to
                                                    automatically start when your stream goes live playing Apex Legends.
                                                    This is a great way to keep your stats up to date without you or a
                                                    mod having to remember to start the game session.
                                                </li>
                                            </ul>
                                            <h3>French Language Support</h3>
                                            <Card.Text className="text-center">
                                                Support for French has been added to the tools. This is in addition to
                                                the current English and Russian languages.
                                                <br />
                                                <br />
                                                If you would like to volunteer to translate the tools into other
                                                languages, please contact me. (
                                                <a
                                                    href="https://twitter.com/MrCaseyJames"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-decoration-none"
                                                >
                                                    <Twitter /> @MrCaseyJames
                                                </a>{" "}
                                                or{" "}
                                                <a
                                                    href="https://discordapp.com/users/471792768503513088"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-decoration-none"
                                                >
                                                    <Discord />
                                                    @mrcaseyjames
                                                </a>
                                                ) I'm always looking for the ability to expand the reach to more people
                                                of all languages.
                                            </Card.Text>
                                            <h3>Overlays Are Now Multilingual</h3>
                                            <Card.Text className="text-center">
                                                Despite having the ability to have commands in a different language,
                                                overlays were strictly English. Now overlays support the same languages
                                                as commands. (English, French, Russian)
                                                <br />
                                                <br />
                                                <img
                                                    src="https://twitchapexstats.com/imgs/update/French-Overlay.png"
                                                    alt="French Overlay Example"
                                                    style={{ maxWidth: "100%" }}
                                                />
                                                <br />
                                                <br />
                                                <img
                                                    src="https://twitchapexstats.com/imgs/update/Russian-Overlay.png"
                                                    alt="Russian Overlay Example"
                                                    style={{ maxWidth: "100%" }}
                                                />
                                            </Card.Text>
                                            <h3>Applying Patreon Benefits</h3>
                                            <Card.Text className="text-center">
                                                There have been changes in how applying Patreon Benefits works. Now you
                                                can pick what channels you want to apply your Patreon benefits to, even
                                                if it isn't your Twitch channel but one you moderate. You can apply your
                                                benefits to multiple channels by changing your pledge amount to a
                                                multiple of how many channels you want to apply your benefits to. ($1
                                                for one channel, $2 for two channels, $8 for eight channels, etc.) All
                                                that is required is that your Twitch and Patreon accounts are linked
                                                through the{" "}
                                                <a
                                                    href="https://dashboard.tas.gg/patreon"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Patreon Settings
                                                </a>{" "}
                                                on the dashboard. You will then see options to apply benefits by the
                                                listed channels on your dashboard.
                                            </Card.Text>
                                            <h3>Other Changes</h3>
                                            <ul>
                                                <li>
                                                    Ranked Arenas Overlays now use the different styled Arenas rank
                                                    icons.
                                                </li>
                                                <li>
                                                    The overlay rank icons for ranked Battle Royale and Arenas for
                                                    Masters/Predator level players now shows their current position on
                                                    the rank icon instead of it being blank.
                                                </li>
                                                <li>
                                                    Added a new variant of the RP Bottom and AP Bottom overlays that is
                                                    a little less boring looking in my opinion.
                                                </li>
                                                <li>
                                                    Added RP Top and AP Top overlays in the same styles as the RP Bottom
                                                    and AP Bottom overlays but for the top of the screen instead.
                                                </li>
                                                <li>
                                                    Added Arenas Legend Kills command and Session Arenas Legend Kills
                                                    commands in the same functionality of the existing BR Legend Kills
                                                    commands but for Arenas.
                                                </li>
                                                <li>
                                                    Added total and session Arenas Legend Kills overlays in the same
                                                    styling of the existing BR Legend Kills overlays
                                                </li>
                                                <li>
                                                    Increased the initial source rendering resolution of all overlays so
                                                    they have increased clarity.
                                                </li>
                                                <li>
                                                    Replaced some of the legend icons in use on the legend related
                                                    overlays with better quality versions.
                                                </li>
                                                <li>Multiple fixes to handle Twitch channel names being changed.</li>
                                                <li>
                                                    Multiple backend changes to increase performance and reliability.
                                                </li>
                                            </ul>
                                            <h1>Migration Process</h1>
                                            <ul>
                                                <li>
                                                    All correctly entered Twitch channel names that are still the
                                                    current channel name have been imported into the dashboard. For
                                                    those channels, if a game session was ever started, that game
                                                    account should be imported to the dashboard as well and tied to the
                                                    channel.
                                                </li>
                                                <li>
                                                    Commands and overlays that were previously created with valid
                                                    details should redirect to the new versions using the default game
                                                    account on the channel. It's still recommended if possible, to
                                                    re-create the commands and overlays to directly use the new versions
                                                    to prevent issues in the future.
                                                </li>
                                                <li>
                                                    Existing overlay options and styling from Patreon users have been
                                                    imported into the new dashboard where the channel name given still
                                                    exists.
                                                </li>
                                                <li>
                                                    <i>Ideally</i> things should all <b>"just work"</b> but if you have
                                                    any problems, recreate the commands or overlays and it should fix
                                                    the issue. I'd recommend recreating things anyways to prevent issues
                                                    in the future. Reach out through the{" "}
                                                    <Link to={"/support"}>support methods</Link> if you need any help.
                                                </li>
                                            </ul>
                                            <h1>Planned Future Features</h1>
                                            <Card.Text className="text-center">
                                                Some features I have planned for the future now that I have a better
                                                base to work on top of for this project.
                                            </Card.Text>
                                            <ul>
                                                <li>
                                                    Ability for Patreon supporters to design their own overlays with
                                                    their own images and design and have automatically filled
                                                    placeholders to fill in session data and other player data where
                                                    they desire.
                                                </li>
                                                <li>
                                                    Ability to design your own commands. Have whatever text you want and
                                                    have sections automatically filled in with whatever player data you
                                                    want wherever you want.
                                                </li>
                                                <li>
                                                    Maybe an official Twitch bot to make configuration even more simple?
                                                    Not sure if needed.
                                                </li>
                                                <li>Always open to suggestions of other things you'd like to see!</li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
}

export default PageUpdate;
