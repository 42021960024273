// Packages
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar, Col, Container } from "react-bootstrap";

// CSS
import "./css/bootstrap-dark.min.css";
import "./css/dashboard.css";
import "./css/dashboard-viewports.css";

// Components
import MainMenuListing from "./components/base/MainMenuListing";
import PageFooter from "./components/base/PageFooter";

// Pages
import PageMain from "./pages/main.page";
import PageSupport from "./pages/support.page";
import PageTwitchStreams from "./pages/twitch-streams.page";
import PageChannelReport from "./pages/channel-report.page";
import PageAnnouncements from "./pages/announcements.page";
import PageCommandExamples from "./pages/commands.page";
import PageOverlayExamples from "./pages/overlays.page";
import PagePatreon from "./pages/patreon.page";
import PageUpdate from "./pages/update.page";

function App() {
    return (
        <Router>
            <Navbar
                collapseOnSelect={true}
                bg="dark"
                variant="dark"
                sticky="top"
                expand="md"
                className="flex-md-nowrap p-0 shadow"
                onSelect={(eventKey) => {
                    //console.log("Selected:", eventKey);
                }}
            >
                <Navbar.Brand
                    href="https://tas.gg"
                    className="col-7 col-sm-4 col-md-3 col-lg-2 me-0 px-3"
                >
                    <img
                        src="https://twitchapexstats.com/android-chrome-192x192.png"
                        alt="Logo"
                        style={({ width: "30px" }, { height: "30px" })}
                    />
                    &nbsp;Twitch Apex Stats
                </Navbar.Brand>

                <Navbar.Toggle
                    id="sidebarButton"
                    aria-controls="sidebarMenu"
                    className="position-absolute"
                />
                <Navbar.Collapse
                    id="sidebarMenu"
                    className="col-7 col-sm-4 col-md-3 col-lg-2 d-md-none bg-light sidebar"
                >
                    <Col className="col-12 sidebar-sticky pt-3 bg-light">
                        <MainMenuListing />
                    </Col>
                </Navbar.Collapse>
            </Navbar>
            <Container fluid>
                <Routes>
                    <Route
                        path="/"
                        element={<PageMain />}
                    />
                    <Route
                        path="/announcements"
                        element={<PageAnnouncements />}
                    />
                    <Route
                        path="/channel-report"
                        element={<PageChannelReport />}
                    />
                    <Route
                        path="/channel-report/:channelName"
                        element={<PageChannelReport />}
                    />
                    <Route
                        path="/commands"
                        element={<PageCommandExamples />}
                    />
                    <Route
                        path="/overlays"
                        element={<PageOverlayExamples />}
                    />
                    <Route
                        path="/patreon"
                        element={<PagePatreon />}
                    />
                    <Route
                        path="/support"
                        element={<PageSupport />}
                    />
                    <Route
                        path="/twitch-streams"
                        element={<PageTwitchStreams />}
                    />
                    <Route
                        path="/update"
                        element={<PageUpdate />}
                    />
                </Routes>
            </Container>
            <PageFooter />
        </Router>
    );
}

export default App;
