import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Row, Col, Card, Container, Button, ListGroup, Modal } from "react-bootstrap";
import { TerminalFill } from "react-bootstrap-icons";

import { useEffectOnce } from "../functions/useEffectOnce";

function PageCommandExamples(props) {
    const { trackPageView } = useMatomo();
    const [showModal, setShowModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalImage, setModalImage] = React.useState("");

    useEffectOnce(() => {
        document.title = "Command Examples - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    const commandExamples = [
        {
            title: "Current Player Stats",
            desc: "See your current level, your Battle Royale rank details (Rank, RP amount, Rank Position in Predator/Master), selected legend, and currently equipped legend trackers (BR Kills, BR Damage, etc).",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/stats.png",
        },
        {
            title: "Ranked Stats",
            desc: "See your Battle Royale rank details (Rank, RP amount, Rank Position in Predator/Master).",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/rank.jpg",
        },
        {
            title: "Rank Session Changes",
            desc: "After starting a session viewers can see how much RP you've gained or lost in this session.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/rp.png",
        },
        {
            title: "Rank Session Recap",
            desc: "See a recap of how much your RP changed in your current session and the prior 4 sessions.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/rp-recap.jpg",
        },
        {
            title: "Next Rank",
            desc: "See how many points you are away from ranking up to the next level. If you are a Masters player you will see approximately how many points you are away from getting Predator. If you are in Predator then it shows how many points are required to reach higher positions in Predator.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/nextrank.png",
        },
        {
            title: "Points To #1 Predator",
            desc: "See how many points you are away from taking #1 Predator. If you are already #1 Predator you will see how much of a lead you have.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/first.jpg",
        },
        {
            title: "Map Rotation",
            desc: "The current maps in rotation for Battle Royale, Battle Royale Ranked. Their remaining time in the rotation and the upcoming maps can be shown as well.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/map.png",
        },
        {
            title: "Predator Cap",
            desc: "See how much RP is needed to reach the barrier to Predator. (PC, Xbox, and Playstation supported.)",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/pred.png",
        },
        {
            title: "Number of Masters/Pred Players",
            desc: "See how many players are in Masters/Pred level rank on each platform. (PC, Xbox, and Playstation supported.)",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/masters.jpg",
        },
        {
            title: "Top 5 Ranked Players",
            desc: "See the current top 5 ranked players on your platform.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/top5.jpg",
        },
        {
            title: "Time Remaining in Current Ranked Split",
            desc: "See how much time is left in the current ranked split.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/timeleft.jpg",
        },
        {
            title: "Legend BR Kills",
            desc: "See how many BR kills you have on a specified legend.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/kills.jpg",
        },
        {
            title: "Session Legend BR Kills",
            desc: "See how many BR kills you have on a specified legend in the current session.",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/sessionkills.jpg",
        },
        {
            title: "Game Server Status",
            desc: "Quickly check and see if there is any issues effecting the Apex Legends servers preventing players from playing. (Status provided by ApexLegendsStatus.com)",
            image: "https://twitchapexstats.com/imgs/commandexamplespage/status.png",
        },
    ];

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        <b>
                                            <TerminalFill /> Command Examples
                                        </b>
                                    </h1>
                                </Col>
                            </Row>
                            <Row
                                className="mb-3"
                                style={{ margin: "auto", maxWidth: "800px" }}
                            >
                                <Col xs="12">
                                    <Card>
                                        <Card.Body>
                                            <Card.Text className="text-center">
                                                <span className="fs-6 fw-bold">
                                                    Below are some examples of available commands.
                                                </span>
                                            </Card.Text>
                                            {commandExamples.map((commandExample, index) => (
                                                <ListGroup.Item key={index}>
                                                    <h5>{commandExample.title}</h5>
                                                    <Row>
                                                        <Col
                                                            xs="12"
                                                            md="4"
                                                            className="text-center text-md-end"
                                                        >
                                                            <img
                                                                src={commandExample.image}
                                                                alt={commandExample.title}
                                                                className="img-fluid"
                                                                onClick={() => {
                                                                    setModalImage(commandExample.image);
                                                                    setModalTitle(commandExample.title);
                                                                    setShowModal(true);
                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs="12"
                                                            md="8"
                                                        >
                                                            <p>{commandExample.desc}</p>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <ShowImageModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={modalTitle}
                image={modalImage}
            />
        </Row>
    );
}

function ShowImageModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img
                    src={props.image}
                    alt={props.title}
                    className="img-fluid"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PageCommandExamples;
