import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Row, Col, Card, Container, ListGroup, Pagination, Spinner } from "react-bootstrap";
import { Discord, Newspaper } from "react-bootstrap-icons";
import DOMPurify from "dompurify";
import ReactTimeAgo from "react-time-ago";

import { useEffectOnce } from "../functions/useEffectOnce";

function PageAnnouncements(props) {
    const { trackPageView } = useMatomo();

    useEffectOnce(() => {
        document.title = "Announcements - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    // Get announcements from API
    const [announcements, setAnnouncements] = useState([]);
    const [announcementsLoaded, setAnnouncementsLoaded] = useState(false);
    const [announcementsPage, setAnnouncementsPage] = useState(1);
    const [announcementsPageCount, setAnnouncementsPageCount] = useState(1);

    useEffect(() => {
        setAnnouncementsLoaded(false);
        axios
            .get("https://api.tas.gg/main/announcements?page=" + announcementsPage)
            .then((response) => {
                setAnnouncements(response.data.data);
                setAnnouncementsPageCount(response.data.last_page);
                setAnnouncementsLoaded(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [announcementsPage]);

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        <b>
                                            <Newspaper /> Announcements/Change Log
                                        </b>
                                    </h1>
                                </Col>
                            </Row>
                            <Row className="mb-3" style={{ margin: "auto", maxWidth: "800px" }}>
                                <Col xs="12">
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>
                                                <span className="fs-5">
                                                    Announcements and changes with Twitch Apex Stats are listed here.
                                                    Check back regularly for updates, or if you are in the{" "}
                                                    <a
                                                        href="https://discord.gg/hkGgMZkanz"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <Discord /> Discord server
                                                    </a>{" "}
                                                    the announcements will be posted there as well.
                                                </span>
                                            </Card.Text>
                                            <ListGroup>
                                                <Pagination className="justify-content-center">
                                                    <Pagination.Item key="Page" disabled>
                                                        Page
                                                    </Pagination.Item>
                                                    <Pagination.Prev
                                                        disabled={announcementsPage === 1}
                                                        onClick={() => setAnnouncementsPage(announcementsPage - 1)}
                                                    />
                                                    {Array.from(Array(announcementsPageCount).keys()).map((i) => (
                                                        <Pagination.Item
                                                            key={"Streams-Page" + (i + 1)}
                                                            active={announcementsPage === i + 1}
                                                            onClick={() => setAnnouncementsPage(i + 1)}
                                                        >
                                                            {i + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next
                                                        disabled={announcementsPage === announcementsPageCount}
                                                        onClick={() => setAnnouncementsPage(announcementsPage + 1)}
                                                    />
                                                </Pagination>
                                                {announcementsLoaded ? (
                                                    announcements.map((announcement) => (
                                                        <ListGroup.Item key={announcement.id}>
                                                            <span className="fs-5 fw-bold">
                                                                {new Date(announcement.timestamp * 1000).toLocaleString(
                                                                    navigator.languages[0],
                                                                    {
                                                                        weekday: "long",
                                                                        year: "numeric",
                                                                        month: "long",
                                                                        day: "numeric",
                                                                    }
                                                                )}
                                                                &nbsp;-&nbsp;
                                                                <ReactTimeAgo
                                                                    date={announcement.timestamp * 1000}
                                                                    timeStyle="round"
                                                                />
                                                            </span>
                                                            <br />
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: DOMPurify.sanitize(
                                                                        announcement.message
                                                                    ).replaceAll(
                                                                        '<i class="bi bi-person-circle"></i>',
                                                                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"><path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path><path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"></path></svg>'
                                                                    ),
                                                                }}
                                                            />
                                                        </ListGroup.Item>
                                                    ))
                                                ) : (
                                                    <ListGroup.Item>
                                                        <div className="text-center col-auto mx-auto pt-5 pb-5">
                                                            <Spinner
                                                                animation="border"
                                                                variant="primary"
                                                                style={{
                                                                    width: "8rem",
                                                                    height: "8rem",
                                                                }}
                                                            />
                                                            <br />
                                                            <span className="pt-5 fs-4">Loading...</span>
                                                        </div>
                                                    </ListGroup.Item>
                                                )}
                                                <Pagination className="justify-content-center pt-3">
                                                    <Pagination.Item key="Page" disabled>
                                                        Page
                                                    </Pagination.Item>
                                                    <Pagination.Prev
                                                        disabled={announcementsPage === 1}
                                                        onClick={() => setAnnouncementsPage(announcementsPage - 1)}
                                                    />
                                                    {Array.from(Array(announcementsPageCount).keys()).map((i) => (
                                                        <Pagination.Item
                                                            key={"Streams-Page" + (i + 1)}
                                                            active={announcementsPage === i + 1}
                                                            onClick={() => setAnnouncementsPage(i + 1)}
                                                        >
                                                            {i + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next
                                                        disabled={announcementsPage === announcementsPageCount}
                                                        onClick={() => setAnnouncementsPage(announcementsPage + 1)}
                                                    />
                                                </Pagination>
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
}

export default PageAnnouncements;
