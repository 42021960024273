import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, Accordion, useAccordionButton, Button, AccordionContext } from "react-bootstrap";
import {
    TrophyFill,
    PersonCircle,
    Newspaper,
    Twitch,
    Discord,
    Clipboard2DataFill,
    TerminalFill,
    QuestionCircleFill,
    Paypal,
} from "react-bootstrap-icons";
import { TfiLayoutMediaOverlayAlt } from "react-icons/tfi";
import { FaPatreon } from "react-icons/fa";
import { GrBeacon } from "react-icons/gr";

import "../../css/dashboard.css";

function MainMenuListing(props) {
    const location = useLocation();

    return (
        <>
            <Nav.Link
                as={Link}
                eventKey="/"
                to="/"
                className={location.pathname === "/" ? "active" : null}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 50 50"
                    style={{ fill: "#333" }}
                >
                    <path d="M45.874,39.515l-20-36c-0.353-0.635-1.396-0.635-1.748,0l-20,36c-0.237,0.426-0.132,0.962,0.25,1.267l7.5,6 C12.059,46.928,12.279,47,12.5,47c0.24,0,0.479-0.086,0.669-0.257l10-9c0.308-0.276,0.413-0.715,0.265-1.102S22.914,36,22.5,36 h-4.422l6.972-14.764L32.841,36H28.5c-0.414,0-0.785,0.255-0.934,0.642s-0.043,0.825,0.265,1.102l10,9 c0.384,0.345,0.968,0.342,1.347-0.009l6.5-6C46.017,40.422,46.098,39.918,45.874,39.515z"></path>
                </svg>{" "}
                Home
            </Nav.Link>
            <Nav.Link href="https://dashboard.tas.gg">
                <PersonCircle /> Dashboard
            </Nav.Link>
            <Accordion className="pt-3">
                <TournamentsMenuToggle eventKey="0">
                    <TrophyFill /> Tournament Commands
                </TournamentsMenuToggle>
                <Accordion.Collapse
                    eventKey="0"
                    className="bg-secondary"
                >
                    <>
                        <Nav.Link
                            className="text-white"
                            href="https://tournaments.tas.gg"
                        >
                            Tournaments Home
                        </Nav.Link>
                        <Nav.Link
                            className="text-white"
                            href="https://tournaments.tas.gg/ongoing"
                        >
                            Ongoing Tournaments
                        </Nav.Link>
                        <Nav.Link
                            className="text-white"
                            href="https://tournaments.tas.gg/upcoming"
                        >
                            Upcoming Tournaments
                        </Nav.Link>
                        <Nav.Link
                            className="text-white"
                            href="https://tournaments.tas.gg/algs"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="16"
                                height="18"
                                viewBox="0 0 50 50"
                                style={{ fill: "#DDDDDD" }}
                            >
                                <path d="M45.874,39.515l-20-36c-0.353-0.635-1.396-0.635-1.748,0l-20,36c-0.237,0.426-0.132,0.962,0.25,1.267l7.5,6 C12.059,46.928,12.279,47,12.5,47c0.24,0,0.479-0.086,0.669-0.257l10-9c0.308-0.276,0.413-0.715,0.265-1.102S22.914,36,22.5,36 h-4.422l6.972-14.764L32.841,36H28.5c-0.414,0-0.785,0.255-0.934,0.642s-0.043,0.825,0.265,1.102l10,9 c0.384,0.345,0.968,0.342,1.347-0.009l6.5-6C46.017,40.422,46.098,39.918,45.874,39.515z"></path>
                            </svg>{" "}
                            ALGS - All Matches
                        </Nav.Link>
                        <Nav.Link
                            className="text-white"
                            href="https://tournaments.tas.gg/als-dgs"
                        >
                            <img
                                src="https://twitchapexstats.com/imgs/als_logo.png"
                                alt="Apex Legends Status logo"
                                style={{
                                    maxHeight: "calc(0.7rem + 0.6vw)",
                                    backgroundColor: "#DDDDDD",
                                    borderRadius: "50%",
                                    padding: "0.1rem",
                                }}
                            />{" "}
                            Apex Legends Status DGS Tournaments
                        </Nav.Link>
                        <Nav.Link
                            className="text-white"
                            href="https://tournaments.tas.gg/als"
                        >
                            <img
                                src="https://twitchapexstats.com/imgs/als_logo.png"
                                alt="Apex Legends Status logo"
                                style={{
                                    maxHeight: "calc(0.7rem + 0.6vw)",
                                    backgroundColor: "#DDDDDD",
                                    borderRadius: "50%",
                                    padding: "0.1rem",
                                }}
                            />{" "}
                            Apex Legends Status Custom Game Stats
                        </Nav.Link>
                    </>
                </Accordion.Collapse>
            </Accordion>
            <Nav.Link
                as={Link}
                eventKey="/announcements"
                to="/announcements"
                className={location.pathname.split("/")[1] === "announcements" ? "pt-4 active" : "pt-4"}
            >
                <Newspaper /> Announcements
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/channel-report"
                to="/channel-report"
                className={location.pathname.split("/")[1] === "channel-report" ? "active" : null}
            >
                <Clipboard2DataFill /> Channel Report
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/commands"
                to="/commands"
                className={location.pathname.split("/")[1] === "commands" ? "active" : null}
            >
                <TerminalFill /> Command Examples
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/overlays"
                to="/overlays"
                className={location.pathname.split("/")[1] === "overlays" ? "active" : null}
            >
                <TfiLayoutMediaOverlayAlt /> Overlay Examples
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/patreon"
                to="/patreon"
                className={location.pathname.split("/")[1] === "patreon" ? "active" : null}
            >
                <FaPatreon /> Patreon Benefits
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/support"
                to="/support"
                className={location.pathname.split("/")[1] === "support" ? "active" : null}
            >
                <QuestionCircleFill /> Help & Support
            </Nav.Link>
            <Nav.Link
                href="https://tas.gg/issues"
                target="_blank"
                rel="noreferrer"
            >
                <GrBeacon /> Service Status
            </Nav.Link>
            <Nav.Link
                as={Link}
                eventKey="/twitch-streams"
                to="/twitch-streams"
                className={location.pathname.split("/")[1] === "twitch-streams" ? "active" : null}
            >
                <Twitch /> Online Twitch Streams
            </Nav.Link>
            <Button
                href="https://patreon.com/TwitchApexStats"
                target="_blank"
                className="btn btn-sm btn-secondary m-2 mt-5 text-black"
                style={{ backgroundColor: "#f96854" }}
            >
                <FaPatreon /> Patreon
            </Button>

            <br />
            <Button
                href="https://www.paypal.com/donate/?business=C9WLUSATV3Z9C&no_recurring=0&item_name=Twitch+Apex+Stats+is+ran+to+help+the+Apex+community.+I+would+love+for+your+help+to+make+sure+it+sticks+around+for+the+future.&currency_code=USD"
                target="_blank"
                className="btn btn-sm btn-secondary m-2 text-black"
                style={{ backgroundColor: "#009cde" }}
            >
                <Paypal /> PayPal Donate
            </Button>

            <br />
            <Button
                href="https://discord.gg/hkGgMZkanz"
                target="_blank"
                className="btn btn-sm btn-secondary m-2 mb-3"
            >
                <Discord /> Discord Server
            </Button>
        </>
    );
}

function TournamentsMenuToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Nav.Link
            className={isCurrentEventKey ? "bg-dark text-white" : null}
            onClick={decoratedOnClick}
        >
            {children}
        </Nav.Link>
    );
}

export default MainMenuListing;
