import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Row, Col, Card, Container, Button, ListGroup, Modal } from "react-bootstrap";

import { TfiLayoutMediaOverlayAlt } from "react-icons/tfi";

import { useEffectOnce } from "../functions/useEffectOnce";

function PageOverlayExamples(props) {
    const { trackPageView } = useMatomo();
    const [showModal, setShowModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalImage, setModalImage] = React.useState("");

    useEffectOnce(() => {
        document.title = "Overlay Examples - Twitch Apex Stats";
        trackPageView();
    });

    // Scroll to top of page
    useEffectOnce(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Row>
            <Col className="col-12 col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5">
                <Container className="mt-0 p-0 pt-1 p-lg-3 p-xl-5">
                    <Row>
                        <Col className="p-0 p-md-1">
                            <Row>
                                <Col className="col-12 text-center">
                                    <h1 className="text-center">
                                        <b>
                                            <TfiLayoutMediaOverlayAlt /> Overlay Examples
                                        </b>
                                    </h1>
                                </Col>
                            </Row>
                            <Row
                                className="mb-3"
                                style={{ margin: "auto", maxWidth: "800px" }}
                            >
                                <Col xs="12">
                                    <Card>
                                        <Card.Body>
                                            <Card.Text className="text-center">
                                                <span className="fs-6 fw-bold">
                                                    Below are some examples of available overlays.
                                                </span>
                                            </Card.Text>
                                            <ListGroup.Item active>
                                                <h5>Color Customization</h5>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col
                                                        xs="12"
                                                        md="4"
                                                        className="text-center text-md-end"
                                                    >
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example1.jpg"
                                                            alt="Color Customization Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example1.jpg"
                                                                );
                                                                setModalTitle("Color Customization Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                    <Col
                                                        xs="12"
                                                        md="8"
                                                    >
                                                        <p>
                                                            We offer the ability to change the color of all the elements
                                                            that make up our overlays so they can be customized to your
                                                            liking and match your current stream color scheme.
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item active>
                                                <h5>General Stats Overlays</h5>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example2.jpg"
                                                            alt="General Stats Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example2.jpg"
                                                                );
                                                                setModalTitle("General Stats Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example3.gif"
                                                            alt="General Stats Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example3.gif"
                                                                );
                                                                setModalTitle("General Stats Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-center fw-bold">Animated Overlay</Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example4.gif"
                                                            alt="General Stats Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example4.gif"
                                                                );
                                                                setModalTitle("General Stats Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-center fw-bold">Animated Overlay</Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item active>
                                                <h5>Ranked RP Overlays</h5>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example5.jpg"
                                                            alt="Ranked RP Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example5.jpg"
                                                                );
                                                                setModalTitle("Ranked RP Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example12.jpg"
                                                            alt="Ranked RP Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example12.jpg"
                                                                );
                                                                setModalTitle("Ranked RP Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example6.jpg"
                                                            alt="Ranked RP Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example6.jpg"
                                                                );
                                                                setModalTitle("Ranked RP Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example7.jpg"
                                                            alt="Ranked RP Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example7.jpg"
                                                                );
                                                                setModalTitle("Ranked RP Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example8.gif"
                                                            alt="Ranked RP Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example8.gif"
                                                                );
                                                                setModalTitle("Ranked RP Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-center fw-bold">Animated Overlay</Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example9.gif"
                                                            alt="Ranked RP Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example9.gif"
                                                                );
                                                                setModalTitle("Ranked RP Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-center fw-bold">Animated Overlay</Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item active>
                                                <h5>BR Kills Overlays</h5>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example10.jpg"
                                                            alt="BR Kills Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example10.jpg"
                                                                );
                                                                setModalTitle("BR Kills Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col className="text-center">
                                                        <img
                                                            src="https://twitchapexstats.com/imgs/overlaysexamplespage/example11.jpg"
                                                            alt="BR Kills Overlay Example"
                                                            className="img-fluid"
                                                            onClick={() => {
                                                                setModalImage(
                                                                    "https://twitchapexstats.com/imgs/overlaysexamplespage/example11.jpg"
                                                                );
                                                                setModalTitle("BR Kills Overlay Example");
                                                                setShowModal(true);
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <ShowImageModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={modalTitle}
                image={modalImage}
            />
        </Row>
    );
}

function ShowImageModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <img
                    src={props.image}
                    alt={props.title}
                    className="img-fluid"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PageOverlayExamples;
