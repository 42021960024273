import React from "react";
import { Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { EyeFill } from "react-bootstrap-icons";

function TwitchStreamContainer(props) {
    return (
        <Col className="text-center mb-3 p-0">
            <Card className="bg-dark p-0 mx-auto" style={{ width: "300px" }}>
                <a
                    href={"https://twitch.tv/" + props.stream_data.channel_name}
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none"
                >
                    <Card.Body className="pt-1 pb-1">
                        <Row>
                            <Col xs="12" className="p-0 mb-1">
                                <img
                                    className="twitch-streams-thumbnail"
                                    src={props.stream_data.thumbnail_url
                                        .replace("{width}", "288")
                                        .replace("{height}", "162")}
                                    width="288"
                                    height="162"
                                    alt={"Preview of " + props.stream_data.channel_name_display + "'s Stream"}
                                />
                            </Col>
                        </Row>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip
                                    id="button-tooltip-2"
                                    style={{
                                        top: "-9999px",
                                        left: "-9999px",
                                    }}
                                >
                                    {props.stream_data.title}
                                </Tooltip>
                            }
                        >
                            <Row>
                                <Col xs="12" className="p-0 text-white text-ellipsis fw-bold">
                                    {props.stream_data.title}
                                </Col>
                            </Row>
                        </OverlayTrigger>

                        <Row>
                            <Col xs="9" className="text-start p-0">
                                <img
                                    className="twitch-streams-channel-icon"
                                    src={props.stream_data.profile_image_url}
                                    style={{ width: "35px" }}
                                    alt={props.stream_data.channel_name_display + "'s Profile Image"}
                                />
                                &nbsp;
                                <span className="h6 text-white">
                                    {props.stream_data.channel_name_display}&nbsp;
                                    {props.stream_data.broadcaster_type === "partner" ? (
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip
                                                    id="button-tooltip-2"
                                                    style={{
                                                        top: "-9999px",
                                                        left: "-9999px",
                                                    }}
                                                >
                                                    Twitch Partner
                                                </Tooltip>
                                            }
                                        >
                                            <svg
                                                fill="#a970ff"
                                                className="twitch-streams-icon-partner"
                                                version="1.1"
                                                viewBox="0 0 20 20"
                                                x="0px"
                                                y="0px"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M10 2l6 2 2 6-2 6-6 2-6-2-2-6 2-6 6-2zM8.889 13.636l5.43-5.429-1.415-1.414-4.015 4.015-2.015-2.015-1.414 1.414 3.429 3.43z"
                                                ></path>
                                            </svg>
                                        </OverlayTrigger>
                                    ) : null}
                                    {props.stream_data.broadcaster_type === "affiliate" ? (
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip
                                                    id="button-tooltip-2"
                                                    style={{
                                                        top: "-9999px",
                                                        left: "-9999px",
                                                    }}
                                                >
                                                    Twitch Affiliate
                                                </Tooltip>
                                            }
                                        >
                                            <svg
                                                className="twitch-streams-icon-affiliate"
                                                viewBox="0 0 16 16"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#fff"
                                            >
                                                <path d="M3.857 0 1 2.857v10.286h3.429V16l2.857-2.857H9.57L14.714 8V0H3.857zm9.714 7.429-2.285 2.285H9l-2 2v-2H4.429V1.143h9.142v6.286z" />
                                                <path d="M11.857 3.143h-1.143V6.57h1.143V3.143zm-3.143 0H7.571V6.57h1.143V3.143z" />
                                            </svg>
                                        </OverlayTrigger>
                                    ) : null}
                                </span>
                            </Col>
                            <Col xs="3" className="text-end p-0">
                                <span className="text-white">
                                    <EyeFill /> {props.stream_data.viewer_count.toLocaleString()}
                                </span>
                            </Col>
                        </Row>
                    </Card.Body>
                </a>
            </Card>
        </Col>
    );
}

export default TwitchStreamContainer;
